@font-face {
  font-display: block;
  font-family: "Segoesc"; /*Can be any text*/
  src: local("Segoesc"), url("./fonts/segoesc.ttf") format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Montserrat"; /*Can be any text*/
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "Montserrat-SemiBold"; /*Can be any text*/
  src: local("Montserrat-SemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoesc",
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
